import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Container, Nav } from 'react-bootstrap'
import anLogo from "../Assets/images/anLogo.webp"


function NavBar2_pr() {
  return (
    <>
      <Navbar className='navbar' expand="sm" style={{color: "white"}} collapseOnSelect={true}>
        <Container >

            
            <Navbar.Brand href='#' className='navbrand'><img src={anLogo} className='HeaderLogo' /></Navbar.Brand>
            {/* <Navbar.Toggle aria-controls='basic-navbar-nav' className='togli'><i className='bi bi-github'></i></Navbar.Toggle> */}
            <Navbar.Toggle aria-controls='basic-navbar-nav' className='togli' />

            <Navbar.Collapse id='basic-navbar-nav' >
                <Nav className='ms-auto linkiContainer'>
                    
                    <Nav.Link href='' className='ml-5' style={{cursor: 'default'}}><Link to="/" className='HeaderNavLink'>Home</Link></Nav.Link>
                    
               

                </Nav>
            </Navbar.Collapse>


        </Container>
    </Navbar> 
    </>
  )
}

export default NavBar2_pr
