import React from 'react'
import { Button, Col, Container, Image, Row, Stack } from 'react-bootstrap'
import Navbar_pr from './Navbar_pr'
import SubHead1 from './SubHead1'
import HalfPage from './HalfPage'


function Header_pr() {
  return (
   <Container className='header' fluid >
    {/* <Row>
        <Col className='zeropadcol'>
            <Navbar_pr />
        </Col>
    </Row> */}

    <Row className='pb-5 pt-5'>
    <div className='tokenBlur2'></div>

        <Col  lg={{span: 5, offset: 0}}  className='mb-5 mb-lg-0 zeropadcol text-center'>
         <SubHead1 />    
        </Col>
        
        <Col  lg={{span: 4, offset: 2}} className='mt-0 mt-lg-5 pt-5 pt-lg-0 zeropadcol text-center'>

            <HalfPage
            classH1="rainbow-dad" 
            mainHeading="Not Another shitcoin, We are the "
             rainbowText="ONE" 
    subHeading="A decentralized, transparent and reliable platform for the small artist. Extraordinary NFT you'll love" 
            Button1Text="Explore now"
            Button2Text="Buy Tokens"
            Button1Icon={""}
            Button2Icon={<i className="bi bi-caret-right-fill"></i>}
             />
            
        </Col>
    </Row>
    
   </Container>


  )
}

export default Header_pr
