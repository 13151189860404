import React from 'react'
import { Container, Row, Col, Stack, Card } from 'react-bootstrap'
import Cardi from './Cardi'
import sphere1 from "../Assets/images/sphere1.webp"
import sphere2 from "../Assets/images/sphere2.webp"
import sphere3 from "../Assets/images/sphere3.webp"


function Section4_pr() {
  return (
    <>
      <Container fluid>
        <Row>
            <Col className='text-center roadmap1'>
              <h1 className='roadmapBig'>ROADMAP</h1>
              <h5 className='roadmapHeading'>ANOTHERONE</h5>
            </Col>
        </Row>

        <Row>
          <Col style={{paddingLeft: "0vw", paddingRight: "0vw"}}>
          <Container>
          <Row>
            <Col md={{span: "4", offset: "0"}} sm={{span: "10", offset: "1"}} xs={{span: "10", offset: "1"}} className='roadmapCard' >
          
            <Cardi
             imgSrc={sphere1}
             cardNum="1."
             cardNumText="One"
             texti1="Smart Contract Creation"
             texti2="Website Launch"
             texti3="Community Building & Marketing push"
             texti4="Whitepaper Launce"
             texti5="500 Telegram Members"
             texti6="Presale Launch"
             texti7="2000 Telegram Members"
             texti8="Token Launch"
             />
             
            </Col>

            <Col  md={{span: "4", offset: "0"}} sm={{span: "10", offset: "1"}} xs={{span: "10", offset: "1"}} className='roadmapCard'>
           
            <Cardi
             imgSrc={sphere2}
             cardNum="2."
             cardNumText="Two"
             texti1="TechRate audit"
             texti2="3,000+ Holders"
             texti3="10,000 Telegram Members"
             texti4="Apply for CoinGecko"
             texti5="Apply for CoinMarketCap"
             texti6="Influencer Marketing Partnerships"
             texti7="User interface development"
             texti8="Pre-Alpha Testing the platform"
             />

            </Col>

            <Col  md={{span: "4", offset: "0"}} sm={{span: "10", offset: "1"}} xs={{span: "10", offset: "1"}} className='roadmapCard'>

            <Cardi
             imgSrc={sphere3}
             cardNum="3."
             cardNumText="Three"
             texti1="Alpha Testing the platform"
             texti2="CEX Listings"
             texti3="Beta Testing the platform"
             texti4="Pitching for the investors"
             texti5="AnotherONE Technical Whitepaper release"
             texti6="Marketing campaigns"
             texti7="AnotherONE  platform launch V1.0"
             texti8="More to announced..."
             />

            </Col>
          </Row>
          </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Section4_pr
