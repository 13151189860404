import * as React from 'react'
import { Route, NavLink, Link, Routes } from 'react-router-dom'
import App from './App'
import Navbar_pr from './Components/Navbar_pr'
import NavBar2_pr from './Components/NavBar2_pr'
// import AboutMe from './Components/AboutMe'
const AboutMe = React.lazy(() => import('./Components/AboutMe'))

function DaddyApp() {
  return (
    <>
    <React.Suspense fallback={<div style={{display: "flex", justifyContent: 'center', alignItems: "center", color: "pink"}}>Loading a heavy page...</div>}>
    <Routes>
    <Route path='/' element={<App />}></Route>
      <Route path='/About-Me' element={<AboutMe />}></Route>
    </Routes>
    </React.Suspense>
    
      
    </>
  )
}

export default DaddyApp
