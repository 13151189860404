import React from 'react'
import { Container, Image, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import anLogo from "../Assets/images/anLogo.webp"

function Navbar_pr() {
  return (
   
    <Navbar className='navbar' expand="sm" style={{color: "white"}} collapseOnSelect={true}>
        <Container >

            <Navbar.Brand href='#' className='navbrand'><img src={anLogo} className='HeaderLogo' alt='logo' /></Navbar.Brand>
            {/* <Navbar.Toggle aria-controls='basic-navbar-nav' className='togli'><i className='bi bi-github'></i></Navbar.Toggle> */}
            <Navbar.Toggle aria-controls='basic-navbar-nav' className='togli' />

            <Navbar.Collapse id='basic-navbar-nav' >
                <Nav className='ms-auto linkiContainer'>
                    {/* <Nav.Link href='#header' className='HeaderNavLink ml-5' ><Link to="/#header">Home</Link></Nav.Link> */}
                    <Nav.Link href='#header' className='HeaderNavLink ml-5'>Home</Nav.Link>
                    <Nav.Link href='#tokeni'  className='HeaderNavLink ml-5'>Tokenization</Nav.Link>
                    <Nav.Link href='#roadmap'  className='HeaderNavLink ml-5'>Roadmap</Nav.Link>
                    <Nav.Link href='#faq'  className='HeaderNavLink ml-5'>FaQ</Nav.Link>
                    <Nav.Link href=''  className='ml-5' style={{cursor: "default"}}><Link to="/About-me" className='HeaderNavLink' style={{textDecoration: "underline"}}>About-Me</Link></Nav.Link>
               

                </Nav>
            </Navbar.Collapse>


        </Container>
    </Navbar> 
    
  )
}

export default Navbar_pr
