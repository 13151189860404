import React from 'react'
import "./App.css"
import "./Assets/css/responsive.css"
import { Col, Container, Row, Stack } from 'react-bootstrap'
import Navbar_pr from './Components/Navbar_pr'
import Header_pr from './Components/Header_pr'
import SubHead1 from './Components/SubHead1'
import Section2_pr from './Components/Section2_pr'
import Section3_pr from './Components/Section3_pr'
import Section4_pr from './Components/Section4_pr'
import Section5_pr from './Components/Section5_pr'
import Footer_pr from './Components/Footer_pr'


function App() {
  return (
  
    <Container fluid={true}>
      <Row>
        <Col className='zeropadcol'  id='header'>
        <Navbar_pr />
        </Col>
      </Row>
      <Row className='pt-3 mt-5'>
        <Col className='zeropadcol'>
        <Header_pr />
        {/* <SubHead1 /> */}
        </Col>
      </Row>

      <Row>
        <Col className='firstGlassRow'  id='tokeni'>
          <Section2_pr />
        </Col>
      </Row>

      <Row style={{background: "black"}}>
        <Section3_pr />
      </Row>

      <Row style={{background: "black", color: "white"}}>
        <Col className='zeropadcol' id='roadmap'>
          <Section4_pr  />
        </Col>
      </Row>

    <Row className='pb-5'>
      <Col xs={{span: "10", offset: "1"}} className='accordiContainer' id="faq">
      <Section5_pr  />
      </Col>
    </Row>

    <Row>
      <Col className='text-center footerParentCol'>
      <Footer_pr />
      </Col>
    </Row>
      
    </Container>
   
  

   
  )
}

export default App
