import React from 'react'
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
// mainHeading
// rainbowText
// subHeading
// Button1
// Button2
// classH1
// linki1
// linki2
function HalfPage(props) {
  return (
    <>
      <Container>
               <Row>
                    <Col>
                    <h1 className={props.classH1}>{props.mainHeading}<span className='rainbow'>{props.rainbowText}</span></h1>

                    </Col>
               </Row>

               <Row>
                <Col className='mt-4'>
                <p className='rainbow-dad-para'>
                    {props.subHeading}
                    </p>
                </Col>
               </Row>

               <Row>
                <Col className='mt-5'>
                <Stack direction='horizontal' gap={5}>
                <div className='backBlursec3'>
                    <div  className='rainbow-but'>
                    <Button variant='dark' size='lg' style={{borderRadius: "15px"}}>{props.Button1Text}{props.Button1Icon}</Button>
                    </div>
                    </div>
                    <div className='glassbut'>

                    <Button href={props.linki2} variant='link'  size='lg' className='rainbow-bro' target='_blank'>{props.Button2Text}{props.Button2Icon}</Button>
                    </div>

                </Stack>
                </Col>
               </Row>
                
            </Container>
    </>
  )
}

export default HalfPage;
