import React from 'react'

function RotatingIcon(props) {
  return (
    <>
     <a href={props.linki} target='_blank'  >
                <i className={props.classy}></i>
    </a> 
    </>
  )
}

export default RotatingIcon
