import React from 'react'
import { Container, Row, Col, Stack, Button } from 'react-bootstrap'
import HalfPage from './HalfPage'

function Section3_pr() {
  return (
    <>
      <Col  lg={{span: 5, offset: 1}} className='mt-0 mt-lg-5 pt-5 pt-lg-0 zeropadcol text-center'>
         
            <HalfPage
            classH1="rainbow-dad secThreeText1" 
            mainHeading="No record labels cutting your album sales. Create, Buy, Sell, Earn with your favourite " rainbowText="NFTs" 
            subHeading="AnothrOne is a deflationary BEP-20 token build on the binance Smart Chain" 
            Button1Text="Explore"
            Button2Text="How it Work"
            Button1Icon={<i className='bi bi-caret-right-fill'></i>}
            Button2Icon={""}
             />
        </Col>
        

        {/* Graphic Column: */}

        <Col className='zeropadcol graphik text-center' lg={{span: "6", offset: "0"}} sm={{span: "11", offset: "1"}} xs={{span: "11", offset: "0"}}>

            <div className='graphikNeuron'></div>
            <div className='blurNeuron'></div>
            <div className='graphikGlassCard0'><h1 className='graphikHeading'>18 Million</h1>Initial Supply</div>
            <div className='graphikGlassCard1'><h1 className='graphikHeading'>0.7Billion</h1>Pre-Sale Supply</div>
            <div className='graphikGlassCard2'><h1 className='graphikHeading'>3%</h1>Development</div>
            <div className='graphikGlassCard3'><h1 className='graphikHeading'>4%</h1>Marketing & Advertising</div>


        </Col>
    </>
  )
}

export default Section3_pr
