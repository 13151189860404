import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css"
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import DaddyApp from './DaddyApp';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>
<Router>
    {/* <App />  */}
    <DaddyApp />
</Router>
    
 </>
);

