import React from 'react'
import { Container, Row, Col,  Stack } from 'react-bootstrap'
import RotatingIcon from './RotatingIcon'
import anLogo from "../Assets/images/anLogo.webp"


function Footer_pr() {

  return (
    <>
     <Container  className=''>
        <Row className='anLogoRow'>
        <img src={anLogo} className='anLogo' alt='logo'/>
        </Row>
        <Row className='footerPara'>
        <Container>A Decentralized, transparent and reliable platform for the small artist. Extraordinary NFT you'll love</Container>
        </Row>
        <Row className='pt-5'>
            <Col>
            <a href='#' className='anchorFooter'>Address</a>
            </Col>
            <Col>
            <a href='#tokeni'  className='anchorFooter'>Tokenomics</a>
            </Col>
            <Col>
            <a href='#roadmap'  className='anchorFooter'>Roadmap</a>
            </Col>
            <Col>
            <a href='#'  className='anchorFooter'>Platform</a>
            </Col>
            <Col>
            <a href='#'  className='anchorFooter'>Whitepaper</a>
            </Col>
        </Row>
       
        <Row className='mt-5 pt-4'>
            <h6>Developed by <a href='https://linkedin.com/in/jishan-arzoo-37788a25b' className='coder'>Jishan Arzoo</a></h6>
        </Row>
        <Row className='text-center pt-2'>
            <Stack direction='horizontal' gap={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                
                <RotatingIcon linki="https://github.com/JishanArzoo" classy="bi bi-github footIcon" />
                <RotatingIcon linki="https://linkedin.com/in/jishan-arzoo-37788a25b" classy="bi bi-linkedin footIcon" />
                <RotatingIcon linki="mailto:jishanarzoo@outlook.com" classy="bi bi-envelope-at-fill footIcon" />
            </Stack>
        </Row>
    </Container> 
    </>
  )
}

export default Footer_pr




