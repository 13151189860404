import React from "react";
import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { Container, Row, Stack } from "react-bootstrap";
import PINK from "../Assets/images/arrow.webp"
import BLUE from "../Assets/images/activeArrow.webp"


// const PINK = 'images/arrow.png';
// const BLUE = 'images/activeArrow.png';

function ContextAwareToggle({  eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = activeEventKey === eventKey;
  
    return (
      <img
      type="button"
        src={ isCurrentEventKey ? PINK : BLUE }
        onClick={decoratedOnClick}
        className="accordiImg"
        alt="accordion_Nav"
      />
      
    );
  }

function Section5_pr() {
return(
    <>
    <Container fluid>
        <Row className="text-center">
        <h1 className='roadmapBig'>F A Q</h1>
        {/* <h5 className='roadmapHeading'>ANOTHERONE</h5> */}
        </Row>
        <Row style={{padding: "5vw 0vw"}}>
        <Accordion>
      <Card className="cardAccordi accordi0">
        <Card.Header>
            <div className="accordi">
            <span className="accordiTitle">Roadmap Details?</span>
            <ContextAwareToggle eventKey="0" />
            </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>The roadmap outlines the planned development and growth trajectory of the platform in three phases, including key milestones and timelines for implementing features and enhancements.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="cardAccordi accordi0">
        <Card.Header>
            <div className="accordi">
            <span className="accordiTitle">Milestone Schedule?</span>
            <ContextAwareToggle eventKey="1" />
            </div>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>The Milestone schedule indicates specific dates or intervals when significant achievements or goals outlined in the roadmap are expected to be reached or completed.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card  className="cardAccordi accordi0">
        <Card.Header>
            <div className="accordi">
            <span className="accordiTitle">How to Buy?</span>
            <ContextAwareToggle eventKey="2" />
            </div>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>To buy NFTs, you'll need a cryptocurrency wallet, then a Marketplace such as "<span className="rainbow">ANOTHERONE</span>", Select the desired NFT on it, and complete the purchase using cryptocurrency.</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
        </Row>
    </Container>
     
    
    </>
)
}

export default Section5_pr;


