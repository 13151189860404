import React from 'react'
import { Card, Stack } from 'react-bootstrap'

function Cardi(props) {
  return (
    <>
     <Card className='tripletsCard'>
            <Card.Img src={props.imgSrc} className='cardImg' alt='sphere'/>
              <Card.Body>
              <Card.Header><h2 className='cardHeading'>{props.cardNum}<br/>Phase<br />{props.cardNumText}<br/><br/><hr className='cardHR'/></h2></Card.Header>
              <Card.Text className='cardText'>
                <Stack direction='vertical' gap={3}>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiWhite'></i> {props.texti1}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiWhite'></i> {props.texti2}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiGray'></i> {props.texti3}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiGray'></i> {props.texti4}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiGray'></i>  {props.texti5}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiGray'></i> {props.texti6}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiGray'></i> {props.texti7}</div>
                  <div className='PromiseContainer'><i className='bi bi-check-circle iconiGray'></i> {props.texti8}</div>
                </Stack>
              </Card.Text>
              
              </Card.Body>
            </Card> 
    </>
  )
}

export default Cardi;
