import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import cube from "../Assets/images/cube.webp"
import torus from "../Assets/images/torus.webp"
import Bsc from "../Assets/images/Bsc.svg"



function Section2_pr() {
  return (
    <>
    <div className='cubeParent text-center'>
    <img src={cube} className='secCube' alt='cube' />
    <img src={torus}  className='secTorus' alt='torus'/>
    </div>

      <Container className='firstGlassRowCol'>
              <Row> 
              <Col className='firstGlassRowColCol' sm={6}>
              <Stack direction='vertical' gap={4}>
              <h1 style={{fontSize: "3rem"}}>Start Buying</h1>
              <div style={{lineHeight: "100%", color: "#8f8f8f"}}>
              <p>Disrupting the Music Industry,Top Notch NFT marketplace</p>
              <p>Revolutionizing the art world with premier NFT innovation</p>
              <p>Elevating the digital space with unparalleled NFT excellence</p>
              </div>

              </Stack>
              </Col>
               
              <Col  sm={6}>
              <Stack className='firstGlassRowColCol' direction='vertical' gap={4}>
                    <Container className='clipboardBa' >
                      <Container fluid>
                        <span className='walletAddress'>0c8950a0x0e3fadb0xdfag0hgj0x0x</span>
                        <span className='copi'>Copy<i className="bi bi-caret-right-fill"></i></span>
                      </Container>
                    </Container>

                    <Container className='belClipboard'>
                      <Row>
                        <Col sm={8} xs={7}>
                        <span style={{fontSize: "1rem", color: "#8f8f8f"}}>Initial Supply Available:<br /> <span style={{color: 'white', fontSize: "1.35rem"}}>ATH 18 Million</span></span>  
                        </Col>
                        <Col sm={4} xs={5}> 
                      <div className='bsc'><img src={Bsc} className='ClipTrust' alt='bsc' /></div>  
                        </Col>
                      

                      
                      </Row>
                      
                    </Container>

                </Stack>
              </Col>
            </Row>
            </Container>
    </>
  )
}

export default Section2_pr
