import React from 'react'
import { Container, Image } from 'react-bootstrap'
import graphic1 from "../Assets/images/GraphicaHead.webp"
import graphic2 from "../Assets/images/GraficaHeader2.webp"
import graphic3 from "../Assets/images/GraphicaHeader3.webp"

function SubHead1() {
  return (
        <Container className='tokenContainer' >
          <div className='tokenBlur'></div>
          
      <Image src={graphic1} className='firstGraphica' alt='mainGraphics' />
      <Image src={graphic2} className='secGraphica' alt='mainGraphics' />
      <Image src={graphic3} className='thirdGraphica' alt='mainGraphics'  />

        </Container>
    
  )
}

export default SubHead1
